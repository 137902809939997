import React from 'react'
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage } from "gatsby-plugin-image"
import { createStyles, Button, Card } from '@mantine/core';

require("prismjs/plugins/line-numbers/prism-line-numbers.css")

const useStyles = createStyles((theme) => ({
  button: {
    a: {
    	color: 'white',
    	fontWeight: 500,
    }
  },
}));

const ManualPost = ({ data, location }) => {

  const { classes } = useStyles();
	const { title, content, contentMarkdown, createdAt, updatedAt, thumbnail, references} = data.contentfulManuals;

	const options = {
		renderText: text => {
			return text.split('\n').reduce((children, textSegment, index) => {
				return [...children, index > 0 && <br key={index} />, textSegment];
			}, []);
		},
		renderMark: {
			[MARKS.CODE]: text => {
				return <pre className="language-plain"><code className="language-plain">{text}</code></pre>
			}
		},
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const imageObj = content.references.find(i => i.contentful_id === node.data.target.sys.id)
				return(
					<GatsbyImage
						image={imageObj.gatsbyImageData}
						alt={imageObj.title}
					/>
				)
			},
		},
	};

	return (
		<Layout>
			<Seo
				pageTitle={title}
				showSiteNameInTitle="true"
				//pageDescription={content.raw.replace(/\n/gi, '').replace(/#/gi, '').slice(0, 90)}
				pagePath={location.pathname}
			/>
			<div className="bg-gray">
				<div className="ast-container ast-container-post">
					<div className="main">
						<Card
							my={40}
							shadow="sm"
							radius="md"
							p={40}
							withBorder
							className="post"
						>
							<h1>{title}</h1>
							<p className="post__date">
								{updatedAt > createdAt ? `Updated on ${updatedAt}` : `Posted on ${createdAt}`}
							</p>
							{thumbnail && //もしサムネイル画像をもっていれば
								<GatsbyImage
									image={thumbnail.gatsbyImageData}
									alt={thumbnail.title}
									className="thumbnail"
								/>
							}
							{contentMarkdown //マークダウン優先
								? <div className="body-text" dangerouslySetInnerHTML={{__html: contentMarkdown.childMarkdownRemark.html}} />
								: <div className="body-text">{documentToReactComponents(content && JSON.parse(content.raw), options)}</div>
							}

							{references && references.map(( url ) => {
								return (
									<>
										<h2 className="reference">参照記事</h2>
										<a href={url} target="_blank" rel="noreferrer">{url}</a>
									</>
									)
							})}

							<p className="post__date__bottom">
								{updatedAt > createdAt ? `Updated on ${updatedAt}` : `Posted on ${createdAt}`}
							</p>

						</Card>
						<div className="cm-header-manualhome-wrapper">
							<Button className={classes.button} size="lg">
								<Link to="/">他の記事を読む（マニュアルTOP）</Link>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default ManualPost;

export const pageQuery = graphql`
	query( $slug: String) {
		contentfulManuals(slug: { eq: $slug }) {
			id
			title
			content {
				raw
				references{
					title
					contentful_id
					publicUrl
					gatsbyImageData
				}
			}
			contentMarkdown{
				contentMarkdown
				childMarkdownRemark {
					html
				}
			}
			thumbnail{
				title
				gatsbyImageData
			}
			references
			createdAt(formatString: "YYYY-MM-DD")
			updatedAt(formatString: "YYYY-MM-DD")
		}
	}
`;